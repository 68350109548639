import { lazy } from "react";

const NewHomePage = lazy(() => import("src/Components/NewHomePage"));
const NoMatchPage = lazy(() => import("src/Components/NoMatch"));
const PrivacyPage = lazy(() => import("src/Components/PrivacyPage"));
const FeaturePage = lazy(() => import("src/Components/FeaturePage"));
const TermsPage = lazy(() => import("src/Components/TermsPage"));
const NewMerchantPage = lazy(() => import("src/Components/NewMerchantPage"));
const DownloadPage = lazy(() => import("src/Components/DownloadPage"));
const ContactUsPage = lazy(() => import("src/Components/ContactUsPage"));
const AboutUsPage = lazy(() => import("src/Components/AboutUsPage"));
const ShopeePayPage = lazy(() => import("src/Components/ShopeePayPage"));
const SPayLaterPage = lazy(() => import("src/Components/SPayLater"));
const SLoanPage = lazy(() => import("src/Components/SLoanPage"));

const RegisterSuccessPage = lazy(() =>
  import("src/Components/RegistrationSuccessPage")
);

export const NAVIGATION_ROUTES = Object.freeze({
  // Root routes
  home: {
    path: "/",
    exact: true,
    element: <NewHomePage />,
  },

  // feature
  feature: {
    path: "/feature",
    element: <FeaturePage />,
  },

  // merchant
  Merchant: {
    path: "/merchant/:type",
    element: <NewMerchantPage />,
  },

  // merchant
  DefaultMerchant: {
    path: "/merchant",
    element: <NewMerchantPage />,
  },

  // Privacy Page
  Privacy: {
    path: "/privacy",
    element: <PrivacyPage />,
  },
  // Terms Page
  Terms: {
    path: "/terms",
    element: <TermsPage />,
  },

  // This page does not exist in current ShopeePay MY
  // RegisterSuccess: {
  //   path: "/register",
  //   search: "?success=true",
  //   element: <RegisterSuccessPage />,
  // },

  // 404 - Must be the last item of the object
  noMatch: {
    path: "*",
    element: <NoMatchPage />,
  },

  // Download Page
  Download: {
    path: "/download",
    element: <DownloadPage />,
  },

  // contactUs Page
  ContactUs: {
    path: "/contact-us",
    element: <ContactUsPage />,
  },
  AboutUs: {
    path: "/about-us",
    element: <AboutUsPage />,
  },
  ShopeePay: {
    path: "/shopeepay",
    element: <ShopeePayPage />,
  },
  ShopeePayLater: {
    path: "/spaylater",
    element: <SPayLaterPage />,
  },
  SLoan: {
    path: "/sloan",
    element: <SLoanPage />,
  },
});
